import $ from 'jquery'
import 'slick-carousel'
import './faq'
import './slider'
import './container-move.js'
import './slideshow'
import './detail-slider'
import './scroll-reveal.js'



window.$ = window.jQuery = $
